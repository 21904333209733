import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { SEO } from '../components/primitives';
import Main from '../components/_cookies/Cookie';

const pageTransition = {
  initial: {},
  enter: {},
  exit: {},
};

const Cookies = ({ location: { pathname } }) => {
  //

  return (
    <motion.article
      className="relative"
      key={pathname}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransition}
      style={{ minHeight: '70vh' }}
    >
      <SEO title="Cookie Policy" description="Cookie Policy" />
      <Main />
    </motion.article>
  );
};

export default Cookies;

Cookies.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
